<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSaveHeader"
            icon="pi pi-save"
            class="mr-2"
            label="Simpan"
            type="submit"
            :disabled="form.obat.length === 0"
          />
          <Button
            :loading="isLoadingBarang"
            :disabled="old_status !== 'draft'"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Barang"
            @click="add"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'PurchaseReturn' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label>Tanggal</label>
                <Calendar
                  v-model="form.pr_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>No Retur</label>
                <InputText v-model="form.pr_no" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label>Status</label>
                <Dropdown
                  v-model="form.status"
                  :options="list_status"
                  :disabled="form.id === 0 || old_status === 'sent'"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
              <div class="field col-12">
                <label
                  :class="{
                    'p-error': v$.form.supplier.$invalid && submitted,
                  }"
                  >Supplier</label
                >
                <AutoComplete
                  v-model="form.supplier"
                  :suggestions="acSupplier"
                  :min-length="2"
                  :class="{
                    'p-invalid': v$.form.supplier.$invalid && submitted,
                  }"
                  class="w-full"
                  input-class="w-full"
                  field="nama"
                  @complete="searchSupplier($event)"
                  @item-select="selectSupplier"
                >
                  <template #item="slotProps">
                    <div>
                      {{ slotProps.item.nama }}
                    </div>
                  </template>
                </AutoComplete>
                <small
                  v-if="
                    (v$.form.supplier.$invalid && submitted) ||
                    v$.form.supplier.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.supplier.required.$message }}</small
                >
              </div>
              <!-- <div class="field col-12 md:col-6">
                <label>Jenis Harga</label>
                <Dropdown
                  v-model="form.jenis_harga"
                  :options="list_jenis_harga"
                  optionValue="value"
                  optionLabel="label"
                  class="w-full"
                />
              </div> -->
              <div class="field col-12">
                <label>Keterangan</label>
                <InputText v-model="form.keterangan" class="w-full" />
              </div>
            </div>
          </div>
        </div>
        <grid-faktur-kembali-detail
          :items="form.obat"
          :loading="isLoading"
          :show-button="true"
          :diskon-tambahan="form.diskon_tambahan"
          :cn="form.cn"
          :dn="form.dn"
          :status="form.status"
          @tambahan="onUpdateDiskonTambahan"
          @edit="onEditData"
          @delete="onConfirmDeletion"
          @cn="onUpdateCn"
          @dn="onUpdateDn"
        />
      </div>
    </form>
    <Dialog
      header="Tambah Barang"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
      maximizable
    >
      <form-retur-pembelian-barang
        :purchase-invoice="list_obat"
        :exclude-list="list_produk"
        @close="onCloseFormBarang"
        @save="onSaveBarang"
      />
    </Dialog>
    <Dialog
      header="Hapus item dari faktur ?"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <div>
        <span
          >Item <strong>{{ item.nama_obat }}</strong> akan dihapus dari daftar
          retur. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogHapus = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeletePO"
        />
      </template>
    </Dialog>
    <Dialog
      header="Edit barang retur"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <form-barang-retur :item="formEdit" @save="onUpdateBarang" />
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import errorHandler from '@/helpers/error-handler'
import FormReturPembelianBarang from '@/components/pembelian/FormReturPembelianBarang'
import GridFakturKembaliDetail from '@/components/pembelian/GridFakturKembaliDetail'
import ReturPembelianService from '@/services/ReturPembelianService'
import PurchaseInvoiceService from '@/services/PurchaseInvoiceService'
import SupplierService from '@/services/SupplierService'
import BatchService from '@/services/BatchService'
import FormBarangRetur from '@/components/pembelian/FormBarangRetur'
import Hotkey from '@/components/Hotkey'
import SettingService from '@/services/SettingService'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    GridFakturKembaliDetail,
    FormReturPembelianBarang,
    FormBarangRetur,
    Hotkey,
  },
  created() {
    this.supplierService = new SupplierService()
    this.returPembelianService = new ReturPembelianService()
    this.purchaseInvoiceService = new PurchaseInvoiceService()
    this.batchService = new BatchService()

    const settingService = new SettingService()
    settingService.get('?filter[key]=RETURN_LIMIT').then((res) => {
      this.return_limit = res.data.data.value ? (res.data.data.value | 0) : 3
    })
  },
  data() {
    return {
      return_limit: 3,
      submitted: false,
      dialogAdd: false,
      dialogHapus: false,
      dialogEdit: false,
      isLoading: false,
      isLoadingBarang: false,
      isLoadingSaveHeader: false,
      acSupplier: [],
      supplierService: null,
      purchaseInvoiceService: null,
      batchService: null,
      item: null,
      deleted: [],
      list_obat: null,
      form: {
        id: 0,
        pr_at: new Date(),
        pr_no: null,
        status: 'draft',
        // pi_id: null,
        // jenis_harga: 2,
        supplier_id: 0,
        keterangan: '',
        obat: [],
        pph: 0,
        cn: 0,
        dn: 0,
      },
      /* list_jenis_harga: [
        { value: 1, label: 'Termasuk Pajak' },
        { value: 2, label: 'Belum Termasuk Pajak' },
        { value: 3, label: 'Non Pajak' },
      ], */
      formEdit: {},
      old_status: 'draft',
      list_status: null,
    }
  },
  mounted() {
    if (this.id > 0) {
      this.isLoading = true
      this.returPembelianService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.pr_at = this.form.pr_at ? new Date(this.form.pr_at) : null
            this.form.supplier = res.data.data.supplier
            this.old_status = this.form.status
            this.form.obat.forEach((e) => {
              e.jumlah = e.harga * e.quantity
            })
            this.getListStatus()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Purchase Order', this)
        })
        .finally(() => (this.isLoading = false))
    }
    this.getListStatus()
  },
  computed: {
    list_produk() {
      return this.form.obat.map((x) => {
        const item = {}

        item.obat_id = x.obat_id
        item.inv_no = x.inv_no
        item.po_no = x.po_no
        item.batch_id = x.batch_id

        return item
      })
    },
  },
  methods: {
    getListStatus() {
      let status = []
      switch (this.old_status) {
        case 'draft':
          status = ['draft', 'sent']
          break
        case 'sent':
          status = ['sent']
          break
        default:
          status.push('draft')
          break
      }
      return (this.list_status = status)
    },
    async getSupplier() {
      const supplierService = new SupplierService()
      return await supplierService
        .get('?sort=nama')
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data supplier', this)
        })
    },
    add() {
      if (!this.form.supplier) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Retur pembelian',
          detail: 'Supplier harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      // Get the current date
      const currentDate = new Date();

      // Get the date three months ago
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(currentDate.getMonth() - this.return_limit);

      // Format the dates in YYYY-MM-DD format
      const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
      const formattedThreeMonthsAgo = threeMonthsAgo.toISOString().slice(0, 10);

      const self = this
      self.isLoadingBarang = true
      this.purchaseInvoiceService
        .get(
          `?filter[supplier_id]=${this.form.supplier.id}&filter[status]=done&filter[pi_between]=${formattedThreeMonthsAgo},${formattedCurrentDate}`
        )
        .then((res) => {
          if (!res.data.data || res.data.data.length === 0) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Purchase Order',
              detail: `Faktur pembelian untuk pelanggan ${this.form.supplier.nama} tidak ditemukan !.`,
              life: 3000,
            })
            self.isLoadingBarang = false
          } else {
            self.list_obat = res.data.data
            self.dialogAdd = true
            self.isLoadingBarang = false
          }
        })
        .catch(() => {
          self.isLoadingBarang = false
        })
    },
    onUpdateDiskonTambahan(item) {
      this.form.diskon_tambahan = item
    },
    async onEditData(item) {
      /*
      if (!item.batch) {
        this.isLoading = true
        const res = await this.batchService.get(`${item.obat_id}`)
        this.isLoading = false
        if (res) {
          if (res.data.status === 200) {
            const data = res.data.data
            if (data) {
              data.forEach((b) => {
                if (b.id === item.batch_id) {
                  item.obat_batch = b
                }
              })
              item.batch = data
              this.formEdit = Object.assign({}, item)
              this.dialogEdit = true
            }
          }
        }
      } else {
        this.formEdit = Object.assign({}, item)
        this.dialogEdit = true
      }
      */
      if (!item.batch) {
        let data = []
        const batch = {
          id: item.batch_id,
          batch_id: item.batch_id,
          batch_no: item.batch_no,
          expired_at: item.expired_at,
        }
        data.push(batch)
        item.batch = data
        item.obat_batch = batch
      }
      this.formEdit = Object.assign({}, item)
      this.dialogEdit = true
    },
    onConfirmDeletion(item) {
      this.item = Object.assign({}, item)
      this.dialogHapus = true
    },
    onDeletePO() {
      if (this.item.id !== 0) {
        this.deleted.push(this.item.id)
      }
      const index = this.form.obat.findIndex(
        (el) => el.id === this.item.id && el.obat_id === this.item.obat_id && el.batch_id === this.item.batch_id
      )
      this.form.obat.splice(index, 1)
      this.item = {}
      this.dialogHapus = false
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      let form = {}

      let obat = []
      this.form.obat.forEach((el) => {
        if (el.id > 0) {
          obat.push({
            id: el.id,
            batch_id: el.batch_id,
            /*keterangan: !el.keterangan ? '' : el.keterangan,*/
            quantity: el.quantity,
          })
        } else {
          obat.push({
            pid_id: el.pid_id,
            batch_id: el.batch_id,
            /*keterangan: !el.keterangan ? '' : el.keterangan,*/
            quantity: el.quantity,
          })
        }
        /* } else if (el.pid_id && el.id === 0) {
          obat.push({
            id: 0,
            pid_id: el.pid_id,
            batch_id: el.batch_id,
            keterangan: !el.keterangan ? '' : el.keterangan,
            quantity: el.quantity,
          })
        } else {
          obat.push({
            pid_id: el.pid_id,
            batch_id: el.batch_id,
            keterangan: !el.keterangan ? '' : el.keterangan,
            quantity: el.quantity,
          })
        } */
      })

      form.id = this.form.id
      form.pr_at = this.form.pr_at
        ? dayjs(this.form.pr_at).format('YYYY-MM-DD HH:mm:ss')
        : null

      // form.pi_id = this.form.pi_id
      form.obat = obat
      form.status = this.form.status

      if (this.form.pr_no) {
        form.pr_no = this.form.pr_no
      }
      if (this.form.supplier) {
        form.supplier_id = this.form.supplier.id
      }
      if (this.form.keterangan) {
        form.keterangan = this.form.keterangan
      }

      form.cn = this.form.cn
      form.dn = this.form.dn

      if (form.obat.length > 0) {
        if (this.form.id === 0) {
          this.isLoadingSaveHeader = true
          this.returPembelianService
            .add(form)
            .then((res) => {
              if (res.data.status === 200) {
                this.$toast.add({
                  severity: 'success',
                  summary: 'Retur pembelian',
                  detail: 'Data berhasil disimpan',
                  life: 3000,
                })
                this.$router.push({ name: 'PurchaseReturn' })
              }
            })
            .catch((err) => {
              errorHandler(err, 'Retur pembelian', this)
            })
            .finally(() => {
              this.isLoadingSaveHeader = false
              this.refreshDetail()
            })
        } else {
          this.isLoadingSaveHeader = true
          form.deleted = this.deleted
          this.returPembelianService
            .update(form)
            .then((res) => {
              if (res.data.status === 200) {
                this.$toast.add({
                  severity: 'success',
                  summary: 'Retur Pembelian',
                  detail: 'Data berhasil disimpan.',
                  life: 3000,
                })
              }
            })
            .catch((err) => {
              errorHandler(err, 'Retur pembelian', this)
            })
            .finally(() => {
              this.deleted = []
              this.isLoadingSaveHeader = false
              this.refreshDetail()
            })
        }
      }
    },
    refreshDetail() {
      if (this.form.id > 0) {
        this.returPembelianService
          .get(`/${this.id}`)
          .then((res) => {
            if (res.data.status === 200) {
              this.form.obat = res.data.data.obat
              this.form.obat.forEach((e) => {
                e.jumlah = e.harga * e.quantity
              })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Retur pembelian (Obat)', this)
          })
      }
    },
    onSaveBarang(item) {
      item.forEach((el) => {
        //this.form.obat.push(el)
        const batch = el.batch
        batch.forEach((b) => {
          let newEl = { ...el }
          delete newEl.batch
          newEl.batch_id = b.batch_id
          newEl.batch_no = b.batch_no
          newEl.expired_at = new Date(b.expired_at)
          newEl.quantity = b.quantity
          let harga_diskon = 0
          if (newEl.harga_diskon) {
            harga_diskon = newEl.harga_diskon
          } else {
            const diskon_persentase = newEl.diskon
              ? ((newEl.harga * newEl.diskon) / 100) * newEl.quantity
              : 0
            const diskon_rp =
              newEl.diskon_harga > 0 ? newEl.diskon_harga * newEl.quantity : 0
            harga_diskon = newEl.diskon > 0 ? diskon_persentase : diskon_rp
          }
          newEl.dpp = harga_diskon * newEl.quantity
          //newEl.harga_total = (harga_diskon + ((newEl.ppn * harga_diskon) / 100)) * newEl.quantity
          newEl.harga_total = newEl.quantity * newEl.harga
          newEl.jumlah = newEl.quantity * newEl.harga
          const index = this.form.obat.findIndex(
            (old) =>
              old.obat_id === newEl.obat_id &&
              old.pid_id === newEl.pid_id &&
              old.batch_id === newEl.batch_id
          )
          if (index === -1) {
            this.form.obat.push(newEl)
          }
        })
      })
      this.dialogAdd = false
    },
    onUpdateBarang(item) {
      const index = this.form.obat.findIndex(
        (el) =>
          el.id === item.id &&
          el.obat_id === item.obat_id &&
          el.pid_id === item.pid_id &&
          el.batch_id === item.batch_id
      )
      this.form.obat[index] = item
      this.dialogEdit = false
    },
    onCloseFormBarang() {
      this.dialogAdd = false
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
    searchSupplier(e) {
      this.supplierService
        .get('?filter[nama]=' + e.query + '&filter[status]=aktif')
        .then((res) => {
          this.acSupplier = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Pelanggan', this)
        })
    },
    selectSupplier(e) {
      this.form.supplier = e.value
      this.form.supplier_id = e.value.id
    },
    /* onUpdateDiskon(item) {
      this.form.diskon_tambahan = item
    },
    onUpdatePPH(item) {
      this.form.pph = item
    }, */
    onUpdateCn(item) {
      this.form.cn = item
    },
    onUpdateDn(item) {
      this.form.dn = item
    },
  },
  validations() {
    return {
      form: {
        supplier: {
          required: helpers.withMessage('Supplier harus diisi.', required),
        },
      },
    }
  },
}
</script>
